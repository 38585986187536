import React, { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '@mui/material/Button';

import Video from '../components/Video';

const Login = () => {

  const { loginWithRedirect } = useAuth0();

  return (
    <Fragment>
      <Button size='large' onClick={() => loginWithRedirect()}>Login</Button>
      <Video/>
    </Fragment>
  );
}

export default Login;