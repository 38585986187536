import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Video from '../components/Video';

const AdminHome = () => {

  const navigate = useNavigate();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Fragment>
      <Stack direction={mobile ? 'column' : 'row'} spacing={6}>
        <Button onClick={() => navigate('/companies')} size='large'>Companies</Button>
        <Button onClick={() => navigate('/solutions')} size='large'>Solutions</Button>
        <Button onClick={() => navigate('/users')} size='large'>Users</Button>
      </Stack>
      <Video/>
    </Fragment>
  );
}

export default AdminHome;