import React, { useState, useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Notifications = props => {

  const { message, error } = props;

  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState('error');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (error) {
      setContent(error);
      setSeverity('error');
      setShow(true);
    } else if (message) {
      setContent(message);
      setSeverity('success');
      setShow(true);
    } else {
      setShow(false);
    }
  }, [message, error]);

  return (
    <Snackbar 
      open={show} 
      key={content} 
      sx={{ mt: 12 }}
      onClose={() => setShow(false)}>
      <Alert severity={severity} onClose={() => setShow(false)}>{content}</Alert>
    </Snackbar>
  );
} 

export default Notifications;