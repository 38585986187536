import * as actionTypes from '../actions/actionTypes';
import { takeLatest } from 'redux-saga/effects';

import {
  fetchSolutionsSaga,
  createSolutionSaga,
  editSolutionSaga,
  deleteSolutionSaga,
  solutionFetchUsersSaga,
  addUsersSaga,
  removeUsersSaga,
} from './solutions';

import {
  fetchUsersSaga,
  filterUsersSaga,
  createUserSaga,
  editUserSaga,
  deleteUserSaga,
  fetchCompanySolutionsSaga,
  addSolutionsSaga,
  removeSolutionsSaga,
  createCompanySaga,
  editCompanySaga,
  deleteComapnySaga
} from './users';

export default function* rootSaga() {
  yield takeLatest(actionTypes.SOLUTIONS_FETCH, fetchSolutionsSaga);
  yield takeLatest(actionTypes.SOLUTIONS_CREATE, createSolutionSaga);
  yield takeLatest(actionTypes.SOLUTIONS_EDIT, editSolutionSaga);
  yield takeLatest(actionTypes.SOLUTIONS_DELETE, deleteSolutionSaga);
  yield takeLatest(actionTypes.SOLUTIONS_FETCH_USERS, solutionFetchUsersSaga)
  yield takeLatest(actionTypes.SOLUTIONS_ADD_USERS, addUsersSaga);
  yield takeLatest(actionTypes.SOLUTIONS_REMOVE_USERS, removeUsersSaga);

  yield takeLatest(actionTypes.USER_FETCH, fetchUsersSaga);
  yield takeLatest(actionTypes.USER_SEARCH_DB, filterUsersSaga);
  yield takeLatest(actionTypes.USER_CREATE, createUserSaga);
  yield takeLatest(actionTypes.USER_EDIT, editUserSaga);
  yield takeLatest(actionTypes.USER_DELETE, deleteUserSaga);
  yield takeLatest(actionTypes.USER_FETCH_SOLUTIONS, fetchCompanySolutionsSaga);
  yield takeLatest(actionTypes.USER_ADD_SOLUTIONS, addSolutionsSaga);
  yield takeLatest(actionTypes.USER_REMOVE_SOLUTIONS, removeSolutionsSaga);
  yield takeLatest(actionTypes.USER_COMPANY_CREATE, createCompanySaga);
  yield takeLatest(actionTypes.USER_COMPANY_EDIT, editCompanySaga);
  yield takeLatest(actionTypes.USER_COMPANY_DELETE, deleteComapnySaga);
}