// Solutions
export const SOLUTIONS_INIT = 'SOLUTIONS_INIT';

export const SOLUTIONS_FETCH = 'SOLUTIONS_FETCH';
export const SOLUTIONS_FETCH_SUCCESS = 'SOLUTIONS_FETCH_SUCCESS';

export const SOLUTIONS_CREATE = 'SOLUTIONS_CREATE';
export const SOLUTIONS_CREATE_SUCCESS = 'SOLUTIONS_CREATE_SUCCESS';

export const SOLUTIONS_EDIT = 'SOLUTIONS_EDIT';
export const SOLUTIONS_EDIT_SUCCESS = 'SOLUTIONS_EDIT_SUCCESS';

export const SOLUTIONS_DELETE = 'SOLUTIONS_DELETE';
export const SOLUTIONS_DELETE_SUCCESS = 'SOLUTIONS_DELETE_SUCCESS';

export const SOLUTIONS_FETCH_USERS = 'SOLUTIONS_FETCH_USERS';
export const SOLUTIONS_FETCH_USERS_SUCCESS = 'SOLUTIONS_FETCH_USERS_SUCCESS';

export const SOLUTIONS_ADD_USERS = 'SOLUTIONS_ADD_USERS';
export const SOLUTIONS_ADD_USERS_SUCCESS = 'SOLUTIONS_ADD_USERS_SUCCESS';

export const SOLUTIONS_REMOVE_USERS = 'SOLUTIONS_REMOVE_USERS';
export const SOLUTIONS_REMOVE_USERS_SUCCESS = 'SOLUTIONS_REMOVE_USERS_SUCCESS';

export const SOLUTIONS_ERROR = 'SOLUTIONS_ERROR';

export const SOLUTIONS_CLEAR_MESSAGE = 'SOLUTIONS_CLEAR_MESSAGE';
export const SOLUTIONS_CLEAR_ERROR = 'SOLUTIONS_CLEAR_ERROR';
export const SOLUTIONS_CLEAR_USERS = 'SOLUTIONS_CLEAR_USERS';


// User
export const USER_INIT = 'USER_INIT';

export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';

export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';

export const USER_EDIT = 'USER_EDIT';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';

export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';

export const USER_FETCH_SOLUTIONS = 'USER_FETCH_SOLUTIONS';
export const USER_FETCH_SOLUTIONS_SUCCESS = 'USER_FETCH_SOLUTIONS_SUCCESS';

export const USER_ADD_SOLUTIONS = 'USER_ADD_SOLUTIONS';
export const USER_ADD_SOLUTIONS_SUCCESS = 'USER_ADD_SOLUTIONS_SUCCESS';

export const USER_REMOVE_SOLUTIONS = 'USER_REMOVE_SOLUTIONS';
export const USER_REMOVE_SOLUTIONS_SUCCESS = 'USER_REMOVE_SOLUTIONS_SUCCESS';

export const USER_SEARCH_DB = 'USER_SEARCH_DB';
export const USER_SEARCH_DB_SUCCESS = 'USER_SEARCH_DB_SUCCESS';

export const USER_FILTER_STATE = 'USER_FILTER_STATE';

export const USER_COMPANY_CREATE = 'USER_COMPANY_CREATE';
export const USER_COMPANY_CREATE_SUCCESS = 'USER_COMPANY_CREATE_SUCCESS';

export const USER_COMPANY_EDIT = 'USER_COMPANY_EDIT';
export const USER_COMPANY_EDIT_SUCCESS = 'USER_COMPANY_EDIT_SUCCESS';

export const USER_COMPANY_DELETE = 'USER_COMPANY_DELETE';
export const USER_COMPANY_DELETE_SUCCESS = 'USER_COMPANY_DELETE_SUCCESS';

export const USER_ERROR = 'USER_ERROR';

export const USER_CLEAR_MESSAGE = 'USER_CLEAR_MESSAGE';
export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR';
export const USER_CLEAR_SOLUTIONS = 'USER_CLEAR_SOLUTIONS';