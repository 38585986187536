import React from 'react';

import Stack from '@mui/material/Stack';

const Video = () => {

  return (
    <Stack sx={{ overflow: 'hidden', position: 'absolute', zIndex: -10 }}>
      <video autoPlay muted loop style={{ margin: 'auto' }} height={window.innerHeight + 150} playsInline>
        <source src='https://www.techmationelectric.com/sites/default/files/videos/techmation_slider_video.mp4' type='video/mp4'/>
      </video>
    </Stack>
  );
}

export default Video;