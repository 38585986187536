import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const LostPage = () => {

  const navigate = useNavigate();

  return (
    <Stack direction='column' spacing={4}>
      <Typography variant='h3' component='h2' color='primary'>What was supposed to be here?</Typography>
      <Button variant='text' onClick={() => navigate('/')}>Return Home</Button>
    </Stack>
  );
}

export default LostPage;