import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Logo from '../assets/images/logo.jpg';

const Header = props => {

  const { auth, admin } = props;

  const [open, toggle] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef();

  const links = [
    { name: 'Companies', path: '/companies' },
    { name: 'Solutions', path: '/solutions' },
    { name: 'Users', path: '/users' },
    { name: 'Logout', path: '/logout' }
  ];

  const route = path => {
    toggle(false);
    navigate(path);
  }

  return (
    <AppBar position='relative' sx={{ mb: 2, py: 1, borderBottom: '2px solid #D40000' }} color='background'>
      <Toolbar>
        <Stack justifyContent='space-between' sx={{ width: '100%' }}>
          <img src={Logo} alt="Techmation Logo" onClick={() => navigate('/')} style={{ '&:hover': { cursor: 'pointer' } }} height={45}/>
          {auth && admin
            ? <Button onClick={() => toggle(true)} ref={menuRef} variant='text' disableRipple>Menu</Button>
            :  auth
              ? <Button onClick={() => route('/logout')} variant='text' disableRipple>Logout</Button>
              : <Button onClick={() => route('/login')} variant='text' disableRipple>Login</Button>}
        </Stack>
      </Toolbar>
      {auth && admin 
        ? <Menu open={open} anchorEl={menuRef.current} onClose={() => toggle(false)} component='nav'>
            {links.map((item, index) => <MenuItem key={index} onClick={() => route(item.path)}>{item.name}</MenuItem>)}
          </Menu>
        : null}
    </AppBar>
  );
}

export default Header;