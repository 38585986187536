import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SwitchTransition} from 'react-transition-group';
import { solutionsClearUsers, solutionsFetch } from '../../store/actions';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Solution from '../../components/Solution';

const SolutionList = props => {

  const { admin } = props;
  const { loading, solutions, total } = useSelector(state => state.solutions);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(solutionsClearUsers());
  }, [dispatch]);

  useEffect(() => {
    let timer;

    if (query.length > 3 && touched) {
      setPage(1);
      setError(null);
      timer = setTimeout(async () => {
        const token = await getAccessTokenSilently();

        dispatch(solutionsFetch(token, { name: query, page: 1 }));
      }, 500);
    } else if (query.length < 3 && touched && query.length > 0) {
      setError('Query must be 3 letter long')
    } else if (touched && query.length === 0) {
      setError(null);
      timer = setTimeout(async () => {
        const token = await getAccessTokenSilently();

        dispatch(solutionsFetch(token, { name: query, page: 1 }));
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [query, touched, getAccessTokenSilently, dispatch]);


  const changed = e => {
    setTouched(true);

    setQuery(e.target.value);
  };

  const changePage = async (_, page) => {
    const token = await getAccessTokenSilently();

    setPage(page);

    dispatch(token, { name: query, page: page });
  } 

  return (
    <Stack direction='column' spacing={4}>
      <Typography variant='h3' component='h2' color='primary'>Solutions</Typography>
      { admin ? <Button size='large' onClick={() => navigate('/solutions/create')}>Create</Button> : null }
      <TextField
        id='solution-search'
        name='solution-search'
        label='Search by Name'
        value={query}
        onChange={changed}
        touched={touched.toString()}
        error={touched && query.length < 3 && query.length !== 0}
        helperText={error}/>
      <SwitchTransition>
        <Grow key={loading ? 'true' : 'false'}>
          {loading
            ? <CircularProgress/>
            : <Grid container component='ul' justifyContent='center'>
                {solutions.length > 0
                    ? solutions.map(item => (<Solution key={item._id} admin={admin} {...item}/>))
                    : <Grid key='placeholder' item component='li'>
                        <Typography>No Solutions to Show</Typography>
                      </Grid> }
              </Grid>} 
        </Grow>
      </SwitchTransition>
      <Pagination
        page={page}
        count={parseInt(Math.ceil(total/50))} 
        disabled={total <= solutions.length}
        onChange={changePage}/>
    </Stack>
  );
}

export default SolutionList;