import { put, delay, spawn } from 'redux-saga/effects';
import { server } from '../../assets/axios/index';
import * as actions from '../actions';

function* clear(type) {
  yield delay(10_000);
  if (type === 'message') yield put(actions.userClearMessage());
  if (type === 'error') yield put(actions.userClearError());
}

export function* fetchUsersSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { data, error } } = yield server.get('/user', {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.userFetchSuccess(data));
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
};

export function* filterUsersSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { data, error } } = yield server.get('/user/filter', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        page: action.data.page,
        company: action.data.company
      }
    });

    if (status === 200) {
      yield put(actions.userFetchSuccess(data));
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* createUserSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { data, error } } = yield server.post('/user', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.userCreateSuccess(data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* editUserSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { data, error } } = yield server.put('/user', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.userEditSuccess(data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* deleteUserSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { error } } = yield server.delete('/user', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        user: action.data
      }
    });

    if (status === 200) {
      yield put(actions.userDeleteSuccess(action.data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* fetchCompanySolutionsSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { data, error } } = yield server.get('/user/solutions', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        user: action.data
      }
    });

    if (status === 200) {
      yield put(actions.userFetchSolutionsSuccess(data));
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* addSolutionsSaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { error } } = yield server.post('/user/solutions', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.addSolutionsSuccess(action.data));
     yield spawn(clear, 'message');

    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* removeSolutionsSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { error } } = yield server.patch('/user/solutions', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.removeSolutionsSuccess(action.data));
     yield spawn(clear, 'message');

    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* createCompanySaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { data, error } } = yield server.post('/company', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.createCompanySuccess(data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* editCompanySaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { error } } = yield server.put('/company', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.editCompanySuccess(action.data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* deleteComapnySaga(action) {
  yield put(actions.userInit());
  try {
    const { status, data: { error } } = yield server.delete('/company', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        company: action.data
      }
    });

    if (status === 200) {
      yield put(actions.deleteCompanySuccess(action.data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.userError(err.message));
    yield spawn(clear, 'error');
  }
}
