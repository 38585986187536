import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { solutionsEdit } from '../../store/actions';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CreateCompany from '../Companies/Create';

const validation = object({
  name: string().required(),
  link: string().url().required(),
  company: string().required()
});

const EditSolution = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { solutions } = useSelector(state => state.solutions);
  const { companies } = useSelector(state => state.user);
  const { id } = useParams();
  const { search } = useLocation();

  const [selected, setSelected] = useState(null);
  const [redirect, setRedirect] = useState('/solutions');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: selected?.name ?? '',
      link: selected?.link ?? '',
      company: selected?.company?._id ?? ''
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: async values => {
      const token = await getAccessTokenSilently();

      dispatch(solutionsEdit(token, { solution: id, ...values }));
     
      return;
    }
  });

  useEffect(() => {
    setSelected(solutions.find(item => item._id === id));
  }, [id, solutions]);

  useEffect(() => {
    if (search) {
      setRedirect(search.split('=')[1]);
    }
  }, [search]);

  return (
    <Stack direction='column' spacing={6}>
      <Typography variant='h4' component='h2' color='primary'>Edit Solution</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction='column' alignItems='stretch' width='90vw' maxWidth='800px'>
          {(() => {
            const inputArray = [];
            
            for (let input in formik.values) {
              inputArray.push(
                input === 'company'
                  ? <TextField
                      select
                      SelectProps={{
                        align: 'left'
                      }}
                      key={input}
                      id={input}
                      name={input}
                      label={input}
                      value={formik.values[input]}
                      onChange={formik.handleChange}
                      error={formik.touched[input] && Boolean(formik.errors[input])}
                      helperText={Boolean(formik.errors[input]) && formik.errors[input]}>
                      {companies.map(item => (
                        <MenuItem key={item.name} value={item._id}>{item.name}</MenuItem>
                      ))}
                    </TextField>
                  : <TextField
                      key={input}
                      id={input}
                      name={input}
                      label={input}
                      type='text'
                      value={formik.values[input]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[input] && Boolean(formik.errors[input])}
                      helperText={formik.touched[input] && formik.errors[input]}/>
              );
            }
                
            return inputArray;
          })()}
          <Button disabled={!formik.dirty || !formik.isValid || formik.isSubmitting} onClick={formik.handleSubmit} sx={{ alignSelf: 'end' }}>Update</Button>
        </Stack>
      </form>
      <Button onClick={() => navigate(`/solutions/delete/${selected?._id}`)}>Delete</Button>
      <CreateCompany/>
      {
        redirect === '/solutions'
          ? <Button variant='text' onClick={() => navigate(redirect)} sx={{ mt: 4 }}>Back to Solutions</Button>
          : <Button variant='text' onClick={() => navigate(redirect)} sx={{ mt: 4 }}>Back to User Management</Button>
      }
      
    </Stack>
  );
}

export default EditSolution;