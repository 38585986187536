import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './theme';

import './index.css';

import App from './App';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorker';

const app = (
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      audience={process.env.REACT_APP_API}
      clientId={process.env.REACT_APP_AUTH_ID}
      redirectUri={process.env.REACT_APP_AUTH_REDIRECT}
      useRefreshTokens={true}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={Theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
register();
