import React from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Company from './Company';
import Create from  './Create';

const CompanyList = () => {

  const { loading, companies } = useSelector(state => state.user);

  return (
    <Stack direction='column'>
      <Typography variant='h3' component='h2' color='primary'>Company List</Typography>
      <Create/>
      <Stack direction='column' alignItems='stretch' width='90vw' maxWidth='800px'>
        <TransitionGroup component={null}>
          {companies.length > 0
            ? companies.map(item => (
                <Collapse key={item.name}>
                  <Company {...item}/>
                </Collapse>
              ))
            : <Collapse key='placeholder'>
                <Typography>{loading ? 'Loading...' : 'No Companies to Show'}</Typography>
              </Collapse>}
        </TransitionGroup>
      </Stack>
    </Stack>
  );
}

export default CompanyList;