import * as actionTypes from './actionTypes';

export const userInit = () => ({ type: actionTypes.USER_INIT });

export const userFetch = token => ({ type: actionTypes.USER_FETCH, token });
export const userFetchSuccess = data => ({ type: actionTypes.USER_FETCH_SUCCESS, data });

export const userCreate = (token, data) => ({ type: actionTypes.USER_CREATE, token, data });
export const userCreateSuccess = data => ({ type: actionTypes.USER_CREATE_SUCCESS, data });

export const userEdit = (token, data) => ({ type: actionTypes.USER_EDIT, token, data });
export const userEditSuccess = data => ({ type: actionTypes.USER_EDIT_SUCCESS, data });

export const userDelete = (token, data) => ({ type: actionTypes.USER_DELETE, token, data });
export const userDeleteSuccess = data => ({ type: actionTypes.USER_DELETE_SUCCESS, data });

export const userFetchSolutions = (token, data) => ({ type: actionTypes.USER_FETCH_SOLUTIONS, token, data });
export const userFetchSolutionsSuccess = data => ({ type: actionTypes.USER_FETCH_SOLUTIONS_SUCCESS, data });

export const addSolutions = (token, data) => ({ type: actionTypes.USER_ADD_SOLUTIONS, token, data });
export const addSolutionsSuccess = data => ({ type: actionTypes.USER_ADD_SOLUTIONS_SUCCESS, data });

export const removeSolutions = (token, data) => ({ type: actionTypes.USER_REMOVE_SOLUTIONS, token, data });
export const removeSolutionsSuccess = data => ({ type: actionTypes.USER_REMOVE_SOLUTIONS_SUCCESS, data });

export const userSearchDb = (token, data) => ({ type: actionTypes.USER_SEARCH_DB, token, data });
export const userSearchDbSuccess = data => ({ type: actionTypes.USER_SEARCH_DB_SUCCESS, data });

export const createCompany = (token, data) => ({ type: actionTypes.USER_COMPANY_CREATE, token, data });
export const createCompanySuccess = data => ({ type: actionTypes.USER_COMPANY_CREATE_SUCCESS, data });

export const editCompany = (token, data) => ({ type: actionTypes.USER_COMPANY_EDIT, token, data });
export const editCompanySuccess = data => ({ type: actionTypes.USER_COMPANY_EDIT_SUCCESS, data });

export const deleteCompany = (token, data) => ({ type: actionTypes.USER_COMPANY_DELETE, token, data });
export const deleteCompanySuccess = data => ({ type: actionTypes.USER_COMPANY_DELETE_SUCCESS, data });

export const userError = error => ({ type: actionTypes.USER_ERROR, error });

export const userClearMessage = () => ({ type: actionTypes.USER_CLEAR_MESSAGE });
export const userClearError = () => ({ type: actionTypes.USER_CLEAR_ERROR });
export const userClearSolutions = () => ({ type: actionTypes.USER_CLEAR_SOLUTIONS });