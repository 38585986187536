import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { createCompany } from '../../store/actions';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const companyValidation = object({
  name: string().required()
});

const CreateCompany = () => {

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const companyForm = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: companyValidation,
    onSubmit: async (values, companyForm) => {
      const token = await getAccessTokenSilently();

      dispatch(createCompany(token, values));
    
      companyForm.resetForm();

      return;
    }
  });

  return (
    <Stack direction='column'>
      <Typography variant='h6' component='h3' color='primary'>Add a Company</Typography>
      <form onSubmit={companyForm.handleSubmit}>
        <Stack direction='column' alignItems='stretch' width='90vw' maxWidth='800px'>
          <TextField
            id='name'
            name='name'
            label='Company Name'
            type='text'
            value={companyForm.values['name']}
            onChange={companyForm.handleChange}
            onBlur={companyForm.handleBlur}
            error={companyForm.touched['name'] && Boolean(companyForm.errors['name'])}
            helperText={companyForm.touched['name'] && companyForm.errors['name']}/>   
          <Button disabled={!companyForm.dirty || !companyForm.isValid || companyForm.isSubmitting} onClick={companyForm.handleSubmit} sx={{ alignSelf: 'end' }}>Create</Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default CreateCompany;