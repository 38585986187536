import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

import Loader from '@mui/material/CircularProgress';

const Auth = () => {

  const { loginWithRedirect, logout } = useAuth0();
  const { pathname } = useLocation();

  useEffect(() => {
    switch(pathname) {
      case '/login': loginWithRedirect(); break;
      case '/logout': logout(); break;
      default: return;
    }
  }, [pathname, loginWithRedirect, logout]);

  return (
    <Loader/>
  );
}

export default Auth;