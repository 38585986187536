import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { solutionsCreate } from '../../store/actions';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CreateCompany from '../Companies/Create';

const validation = object({
  name: string().required(),
  link: string().url().required(),
  company: string().required()
});

const CreateSolution = () => {

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companies } = useSelector(state => state.user);

  const formik = useFormik({
    initialValues: {
      name: '',
      link: '',
      company: ''
    },
    validationSchema: validation,
    onSubmit: async (values, formik) => {
      const token = await getAccessTokenSilently();

      dispatch(solutionsCreate(token, values));

      formik.resetForm();

      return;
    }
  });

  return (
    <Stack direction='column' spacing={4}>
      <Typography variant='h4' component='h2' color='primary'>Create Solution</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction='column' alignItems='stretch' width='90vw' maxWidth='800px'>
          {(() => {
            const inputArray = [];
            
            for (let input in formik.values) {
              inputArray.push(
                input === 'company'
                  ? <TextField
                      select
                      SelectProps={{
                        align: 'left'
                      }}
                      key={input}
                      id={input}
                      name={input}
                      label={input}
                      value={formik.values[input]}
                      onChange={formik.handleChange}
                      error={formik.touched[input] &&Boolean(formik.errors[input])}
                      helperText={Boolean(formik.errors[input]) && formik.errors[input]}>
                      {companies.map(item => (
                        <MenuItem key={item.name} value={item._id}>{item.name}</MenuItem>
                      ))}
                    </TextField>
                  : <TextField
                      key={input}
                      id={input}
                      name={input}
                      label={input}
                      type='text'
                      value={formik.values[input]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[input] && Boolean(formik.errors[input])}
                      helperText={formik.touched[input] && formik.errors[input]}/>
              );
            }
                
                return inputArray;
              })()}
          <Button disabled={!formik.dirty || !formik.isValid || formik.isSubmitting} onClick={formik.handleSubmit} sx={{ alignSelf: 'end' }}>Create</Button>
        </Stack>
      </form>
      <CreateCompany/>
      <Button variant='text' onClick={() => navigate('/solutions')} sx={{ mt: 4 }}>Back to Solutions</Button>
    </Stack>
  );
}

export default CreateSolution;