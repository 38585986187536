import axios from 'axios';

export const server = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: status => status < 500,
  withCredentials: true
});

export const auth0server = axios.create({
  baseURL: `https://${process.env.REACT_APP_AUTH_DOMAIN}`,
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: status => status < 500
});