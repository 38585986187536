import React, { useState, useEffect, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { TransitionGroup, SwitchTransition } from 'react-transition-group';
import { solutionsFetchUsers, addUsers, removeUsers } from '../../store/actions';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const UserAssignment = () => {

  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { loading, solutions, users } = useSelector(state => state.solutions);
  const [selected, select] = useState(null);
  const [approved, setApproved] = useState([]);
  const [unapproved, setUnapproved] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);

  useEffect(() => {
    select(solutions.find(solution => solution._id === id));
  }, [solutions, id]);

  useEffect(() => {
    if (selected !== null) {
      (async () => {
        const token = await getAccessTokenSilently();
        
        dispatch(solutionsFetchUsers(token, selected.company?._id));
      })();
    }
  }, [selected, id, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    if (selected !== null) {
      setApproved([]);
      setUnapproved([]);

      for (let user of users) {
        selected.users.includes(user.user_id) ? setApproved(prev => [...prev, user]) : setUnapproved(prev => [...prev, user]);
      }

    }
  }, [users, selected, selected?.users]);

  const toggleAdd = id => {
    usersToAdd.includes(id) ? setUsersToAdd(prev => prev.filter(user => user !== id)) : setUsersToAdd(prev => [...prev, id]);
  }

  const toggleRemove = id => {
    usersToRemove.includes(id) ? setUsersToRemove(prev => prev.filter(user => user !== id)) : setUsersToRemove(prev => [...prev, id]);
  }

  const add = async () => {
    const token = await getAccessTokenSilently();

    dispatch(addUsers(token, { id, users: usersToAdd }));

    setUsersToAdd([]);
  }

  const remove = async () => {
    const token = await getAccessTokenSilently();

    dispatch(removeUsers(token, { id, users: usersToRemove }));

    setUsersToRemove([]);
  }

  return (
    <Stack direction='column'>
      <Typography variant='h3' component='h2' color='primary'>{selected?.name ?? 'Solution'}</Typography>
      <Typography sx={{ maxWidth: '90vw', wordBreak: 'break-word' }}>{selected?.link ?? 'Link'}</Typography>
      <Typography variant='h6' component='p'>{`Only users approved for ${selected?.company?.name} are shown`}</Typography>
      <Button variant='text' onClick={() => navigate(`/solutions/edit/${id}?redirect=${pathname}`)}>Manage Companies Here</Button>
      <Stack direction={mobile ? 'column' : 'row'}>
        <Card sx={{ height: '60vh' }}>
          <CardHeader
            title='Unapproved Users'
            subheader='Select any user to add'
            action={
              <Button 
                onClick={add} 
                variant='text' 
                size='small' 
                sx={{ ml: 3 }} 
                disabled={usersToAdd.length === 0}>Add Users</Button>
            }/>
          <Divider/>
          <CardContent sx={{  maxHeight: mobile ? '75%' : '90%'}}>
              <SwitchTransition>
                <Fade key={selected === null ? 'loader' : 'data'}>
                  <List>
                    {selected !== null 
                      ? <Fragment>
                          <TransitionGroup component={null}>
                            {unapproved.map(user => (
                              <Collapse key={user.user_id}>
                                <ListItemButton onClick={() => toggleAdd(user.user_id)}>
                                  <ListItemIcon><Checkbox disableRipple checked={usersToAdd.includes(user.user_id)}/></ListItemIcon>
                                  <ListItemText 
                                    primary={user.name} 
                                    secondary={user.email}/>
                                </ListItemButton>
                              </Collapse>
                            ))}
                          </TransitionGroup>
                          <Collapse in={users.length === 0 && !loading}>
                            <ListItem>
                              <ListItemText primary='No users to show, check companies'/>
                            </ListItem>
                          </Collapse>
                        </Fragment>
                      : <ListItem>
                          <CircularProgress/>
                        </ListItem>}
                </List>
              </Fade>
            </SwitchTransition>
          </CardContent>
        </Card>

        <Fade in={loading}>
          <CircularProgress size={60}/>
        </Fade>

        <Card sx={{ height: '60vh' }}>
          <CardHeader
            title='Approved Users'
            subheader='Select any user to remove'
            action={
              <Button 
                onClick={remove} 
                variant='text' 
                size='small' 
                sx={{ ml: 3 }} 
                disabled={usersToRemove.length === 0}>Remove Users</Button>
            }/>
          <Divider/>
          <CardContent sx={{  maxHeight: mobile ? '75%' : '90%'}}>
            <SwitchTransition>
                <Fade key={selected === null ? 'loader' : 'data'}>
                  <List>
                    {selected !== null 
                      ? <TransitionGroup component={null}>
                          {approved.map(user => (
                            <Collapse key={user.user_id}>
                              <ListItemButton onClick={() => toggleRemove(user.user_id)}>
                                <ListItemIcon><Checkbox disableRipple checked={usersToRemove.includes(user.user_id)}/></ListItemIcon>
                                <ListItemText 
                                  primary={user.name} 
                                  secondary={user.email}/>
                              </ListItemButton>
                            </Collapse>
                          ))}
                        </TransitionGroup>
                      : <ListItem>
                          <CircularProgress/>
                        </ListItem>}
                </List>
              </Fade>
            </SwitchTransition>
          </CardContent>
        </Card>
      </Stack>
      <Button variant='text' onClick={() => navigate('/solutions')} sx={{ mt: 4 }}>Back to Solutions</Button> 
    </Stack>
  );
}

export default UserAssignment;