import React from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Footer = () => {

  const navigate = useNavigate();

  return (
    <Container component='footer' sx={{ boxShadow: '5px 3px 7px 5px #00000080', py: 1, mt: 2,  borderTop: '2px solid #D40000', backgroundColor: '#FFF' }} maxWidth={false}>
      <Stack justifyContent='space-between'>
        <Typography sx={{ '&:hover': { cursor: 'pointer' } }} color='primary' onClick={() => navigate('/support')}>Installation Help</Typography>
        <Stack justifyContent='end' alignItems='end' alignSelf='end' spacing={0}>
          <Typography variant='body1' gutterBottom>Developed by</Typography>
          <Link variant='h4' underline='hover' sx={{ '&:hover': { cursor: 'pointer' } }} href='https://mitchellaneous.ca/'>MWD</Link>
        </Stack>
      </Stack>
    </Container>
  );
}

export default Footer;