import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Edit from '@mui/icons-material/Edit';

const Solution = forwardRef((props, ref) => {

  const { user_id, name, email, app_metadata: { admin }, style } = props;

  const navigate = useNavigate();

  const route = (path, id) => {
    if (admin) {
      navigate(`/admins/${path}/${id}`);
    } else {
      navigate(`/users/${path}/${id}`);
    }
  }

  return (
    <Grid item component='li'>
      <Card style={style} ref={ref}>
        <CardHeader 
          action={
            <IconButton onClick={() => route('edit', user_id)}>
              <Edit color='primary'/>
            </IconButton>
          }
          title={name}/>
        <Divider/>
        <CardContent>
          <Stack direction='column' spacing={4}>
            <Stack spacing={1}><Typography>e-mail:</Typography><Typography>{email}</Typography></Stack>
              {admin ? null : <Button size='small' onClick={() => navigate(`/users/solutions/${user_id}`)}>Manage Solutions</Button>}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
});

export default Solution;