import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { TransitionGroup, SwitchTransition } from 'react-transition-group';
import { userFetchSolutions, addSolutions, removeSolutions } from '../../store/actions';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const SolutionAssignment = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { loading, users, solutions } = useSelector(state => state.user);
  const [selected, select] = useState(null);
  const [approved, setApproved] = useState([]);
  const [unapproved, setUnapproved] = useState([]);
  const [solutionsToAdd, setSolutionsToAdd] = useState([]);
  const [solutionsToRemove, setSolutionsToRemove] = useState([]);


  useEffect(() => {
    select(users.find(user => user.user_id === id));
  }, [users, id]);

  useEffect(() => {
    if (selected !== null) {
      (async () => {
        const token = await getAccessTokenSilently();

        dispatch(userFetchSolutions(token, selected?.user_id));
      })();
    }
  }, [selected, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    setApproved([]);
    setUnapproved([]);

    for (let solution of solutions) {
      solution.users.includes(id) ? setApproved(prev => [...prev, solution]) : setUnapproved(prev => [...prev, solution]);
    }
  }, [solutions, id]);

  const toggleAdd = id => {
    solutionsToAdd.includes(id) ? setSolutionsToAdd(prev => prev.filter(solution => solution !== id)) : setSolutionsToAdd(prev => [...prev, id]);
  }

  const toggleRemove = id => {
    solutionsToRemove.includes(id) ? setSolutionsToRemove(prev => prev.filter(solution => solution !== id)) : setSolutionsToRemove(prev => [...prev, id]);
  }

  const add = async () => {
    const token = await getAccessTokenSilently();

    dispatch(addSolutions(token, { id, solutions: solutionsToAdd }));

    setSolutionsToAdd([]);
  }

  const remove = async () => {
    const token = await getAccessTokenSilently();

    dispatch(removeSolutions(token, { id, solutions: solutionsToRemove }));

    setSolutionsToRemove([]);
  }

  return (
    <Stack direction='column'>
      <Typography variant='h3' component='h2' color='primary'>{selected?.name ?? 'User'}</Typography>
      <Typography variant='h6' component='p'>{selected?.email ?? 'Email'}</Typography>
      <Stack direction={mobile ? 'column' : 'row'} sx={{ my: 1 }}>
        <Card sx={{ height: '60vh', maxWidth: mobile ? '90vw' : '45vw' }}>
          <CardHeader
            title='Unapproved Solutions'
            subheader='Select all to add'
            action={
              <Button 
                onClick={add} 
                variant='text' 
                size='small' 
                sx={{ ml: 3 }} 
                disabled={solutionsToAdd.length === 0}>Add Solutions</Button>
              }/>
          <Divider/>
          <CardContent sx={{  maxHeight: mobile ? '75%' : '90%'}}>
              <SwitchTransition>
                <Fade key={selected === null ? 'loader' : 'data'}>
                  <List sx={{ overflowY: 'auto' }}>
                    {selected !== null 
                      ? <TransitionGroup component={null}>
                          {unapproved.map(solution => (
                            <Collapse key={solution._id}>
                              <ListItemButton onClick={() => toggleAdd(solution._id)}>
                                <ListItemIcon><Checkbox disableRipple checked={solutionsToAdd.includes(solution._id)}/></ListItemIcon>
                                <ListItemText primary={solution.name} secondary={solution?.company?.name}/>
                              </ListItemButton>
                            </Collapse>
                          ))}
                        </TransitionGroup>
                      : <ListItem>
                          <CircularProgress/>
                        </ListItem>}
                </List>
              </Fade>
            </SwitchTransition>
          </CardContent>
        </Card>

        <Fade in={loading}>
          <CircularProgress size={60}/>
        </Fade>

        <Card sx={{ height: '60vh', maxWidth: mobile ? '90vw' : '45vw' }}>
          <CardHeader
            title='Approved Solutions'
            subheader='Select all to remove'
            action={
              <Button 
                onClick={remove} 
                variant='text' 
                size='small' 
                sx={{ ml: 3 }} 
                disabled={solutionsToRemove.length === 0}>Remove Solutions</Button>
            }/>
          <Divider/>
          <CardContent sx={{  maxHeight: mobile ? '75%' : '90%'}}>
            <SwitchTransition>
                <Fade key={selected === null ? 'loader' : 'data'}>
                  <List>
                    {selected !== null 
                      ? <TransitionGroup component={null}>
                          {approved.map(solution => (
                            <Collapse key={solution._id}>
                              <ListItemButton onClick={() => toggleRemove(solution._id)}>
                                <ListItemIcon><Checkbox disableRipple checked={solutionsToRemove.includes(solution._id)}/></ListItemIcon>
                                <ListItemText primary={solution.name} secondary={solution.company?.name}/>
                              </ListItemButton>
                            </Collapse>
                          ))}
                        </TransitionGroup>
                      : <ListItem>
                          <CircularProgress/>
                        </ListItem>}
                </List>
              </Fade>
            </SwitchTransition>
          </CardContent>
        </Card>
      </Stack>
      <Button variant='text' onClick={() => navigate('/users')} sx={{ mt: 4 }}>Back to Users</Button> 
    </Stack>
  );
}

export default SolutionAssignment;