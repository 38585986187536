import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  message: null,
  solutions: [],
  users: [],
  more: false,
  total: 0
};

const editSolution = (state, action) => ({
  ...state, 
  loading: false, 
  message: 'Solution Updated', 
  solutions: state.solutions.map(item => item._id === action.data.solution ? {
    ...item,
    name: action.data.name,
    link: action.data.link,
    company: action.data.company
  } : item)})

const addSolutions = (state, action) => ({
  ...state,
  loading: false,
  message: 'Solutions added to User',
  solutions: state.solutions.map(solution => {
    if (action.data.solutions.includes(solution._id)) {
      solution.users = [...solution.users, action.data.id].sort(sort);

      return solution;
    } else {
      return solution;
    }
  })
});

const removeSolutions = (state, action) => ({
  ...state,
  loading: false,
  message: 'Solutions removed from User',
  solutions: state.solutions.map(solution => {
    if (action.data.solutions.includes(solution._id)) {
      solution.users = solution.users.filter(id => id !== action.data.id);

      return solution;
    } else {
      return solution;
    }
  })
});

const addUsers = (state, action) => ({
  ...state,
  loading: false,
  message: 'Users added to Solution',
  solutions: state.solutions.map(solution => {
    if (solution._id === action.data.id) {
      solution.users = [...solution.users, ...action.data.users];
      
      return solution;
    } else {
      return solution;
    }
  })
});

const removeUsers = (state, action) => ({
  ...state,
  loading: false,
  message: 'Users removed from Solution',
  solutions: state.solutions.map(solution => {
    if (solution._id === action.data.id) {
      solution.users = solution.users.filter(user => !action.data.users.includes(user));

      return solution;
    } else {
      return solution;
    }
  })
});

const sort = (a, b) => ( a.name >= b.name ? 1 : -1);

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case actionTypes.SOLUTIONS_INIT: return { ...state, loading: true, error: null, message: null };
    case actionTypes.SOLUTIONS_FETCH_SUCCESS: return { ...state, loading: false, solutions: action.data.solutions, total: action.data.total };
    case actionTypes.SOLUTIONS_CREATE_SUCCESS: return { ...state, loading: false, message: 'Solution Created', solutions: [ ...state.solutions, action.data ].sort(sort) };
    case actionTypes.SOLUTIONS_EDIT_SUCCESS: return editSolution(state, action);
    case actionTypes.SOLUTIONS_DELETE_SUCCESS: return { ...state, loading: false, message: 'Solution Removed', solutions: state.solutions.filter(item => item._id !== action.data)};
    case actionTypes.SOLUTIONS_FETCH_USERS_SUCCESS: return { ...state, loading: false, users: action.data };
    case actionTypes.SOLUTIONS_ADD_USERS_SUCCESS: return addUsers(state, action);
    case actionTypes.SOLUTIONS_REMOVE_USERS_SUCCESS: return removeUsers(state, action);
    case actionTypes.USER_ADD_SOLUTIONS_SUCCESS: return addSolutions(state, action);
    case actionTypes.USER_REMOVE_SOLUTIONS_SUCCESS: return removeSolutions(state, action);
    case actionTypes.SOLUTIONS_ERROR: return { ...state, loading: false, error: action.error };
    case actionTypes.SOLUTIONS_CLEAR_MESSAGE: return { ...state, message: null };
    case actionTypes.SOLUTIONS_CLEAR_ERROR: return { ...state, error: null };
    case actionTypes.SOLUTIONS_CLEAR_USERS: return { ...state, users: [] };
    default: return state;
  }
};