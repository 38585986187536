import React from 'react';
import { useMediaQuery } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Expand from '@mui/icons-material/ExpandMore';

import ios1 from '../assets/images/ios_step1.png';
import ios2 from '../assets/images/ios_step2.png';
import ios3 from '../assets/images/ios_step3.png';
import androidPrompt from '../assets/images/android_prompt.png';
import android1 from '../assets/images/android_step1.png';
import android2 from '../assets/images/android_step2.png';

const Support = () => {

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h3' component='h2' color='primary'>Installation Help</Typography>
      <Typography>Choose your platform</Typography>
      <Stack direction={mobile ? 'column' : 'row'} alignItems={mobile ? 'center' : 'start'}>
        <Accordion>
          <AccordionSummary expandIcon={<Expand color='primary'/>}>
            <Typography variant='h6' color='primary' component='h3'>Apple iOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ maxWidth: 400, maxHeight: '100%' }}>
              <ListItem>
                <ListItemAvatar>
                  <img src={ios1} alt='ios step one assistance'/>
                </ListItemAvatar>
                <ListItemText
                  primary='Open The Browser Menu'
                  secondary='Once you have the website open you will need to click the menu icon at the bottom of the page.'/>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <img src={ios2} alt='ios step one assistance'/>
                </ListItemAvatar>
                <ListItemText
                  primary='Add To Home Screen'
                  secondary='Once you have the menu open you will need to click "Add to Home Screen".'/>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <img src={ios3} alt='ios step one assistance'/>
                </ListItemAvatar>
                <ListItemText
                  primary='Name The Shortcut'
                  secondary='The name will autofill as Techmation. Click "add" and you are done'/>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<Expand color='primary'/>}>
            <Typography variant='h6' color='primary' component='h3'>Android</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ maxWidth: 400, maxHeight: '100%   ' }}>
              <ListItem>
                <ListItemAvatar>
                  <img src={androidPrompt} alt='android step one assistance'/>
                </ListItemAvatar>
                <ListItemText
                  primary='Initial Prompt'
                  secondary='This prompt will appear at the bottom of the page, if it disappears refresh the page. If you click dismiss or the X follow the next steps.'/>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <img src={android1} alt='android step two assistance'/>
                </ListItemAvatar>
                <ListItemText
                  primary='Open The Browser Menu'
                  secondary='Click the three dots in the upper right hand corner.'/>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <img src={android2} alt='android step three assistance'/>
                </ListItemAvatar>
                <ListItemText
                  primary='Install App'
                  secondary='Once you have the menu open you will need to click "Install App". The app will then install and add itself to the home screen.'/>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Typography variant='h6' component='div'>Call: <Link href='tel:780-206-9511' underline='hover'>(780) 206-9511</Link></Typography>
      <Typography variant='h6' component='div'>Email: <Link href='mailto:scada.support@techmationelectric.com' underline='hover'>scada.support@techmationelectric.com</Link></Typography>
    </Stack>
  );
}

export default Support;