import React, { forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import DoubleArrow from '@mui/icons-material/DoubleArrow';
import Edit from '@mui/icons-material/Edit';

const Solution = forwardRef((props, ref) => {

  const { admin, _id, name, company: { name:companyName }, link, style } = props;

  const navigate = useNavigate(); 
  const linkRef = useRef();

  const cardAction = <IconButton onClick={() => navigate(`/solutions/edit/${_id}`)}><Edit color='primary'/></IconButton>

  return (
    <Grid item component='li'>
      <Card style={style} ref={ref}>
        <CardHeader 
          title={name} 
          subheader={companyName}
          action={admin ? cardAction : null}/>
        <Divider/>
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Stack justifyContent='end' sx={{ width: '100%' }}>
              <Link variant='h6' href={link} underline='hover' ref={linkRef} target='_blank'>Go To Solution</Link>
              <IconButton onClick={() => linkRef.current.click()}>
                <DoubleArrow color='primary'/>
              </IconButton>
            </Stack>
            {admin
              ? <Stack direction='column'>
                  <Button size='small' onClick={() => navigate(`/solutions/users/${_id}`)}>Manage Users</Button>
                </Stack>
              : null}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
});

export default Solution;