import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string, array } from 'yup';
import { userEdit } from '../../store/actions';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const validation = object({
  email: string().email().required(),
  name: string().required(),
  company: array().min(1)
});

const EditUser = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { users, admins, companies } = useSelector(state => state.user);
  const { id } = useParams();
  const { pathname } = useLocation();

  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: selected?.email ?? '',
      name: selected?.name ?? '',
      companies: companies.filter(company => company.users.includes(id)).flatMap(company => company._id) ?? []
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: async values => {
      const token = await getAccessTokenSilently();

      dispatch(userEdit(token, { id, ...values}));

      return;
    }
  });

  useEffect(() => {
    if (pathname.includes('users')) {
      setSelected(users.find(item => item.user_id === id));
    } else {
      setSelected(admins.find(item => item.user_id === id));
    }
  }, [pathname, admins, users, id]);

  return (
    <Stack direction='column' spacing={6}>
      <Typography variant='h4' component='h2' color='primary'>Edit User</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction='column' alignItems='stretch' width='90vw' maxWidth='800px'>
          {(() => {
            const inputArray = [];
            
            for (let input in formik.values) {
              inputArray.push(
                input === 'companies'
                  ? <TextField
                      select
                      SelectProps={{
                        align: 'left',
                        multiple: true
                      }}
                      key={input}
                      id={input}
                      name={input}
                      label={input}
                      value={formik.values[input]}
                      onChange={formik.handleChange}
                      error={formik.touched[input] && Boolean(formik.errors[input])}
                      helperText={formik.errors[input] ? formik.error[input] : 'Select All Comapnies that Apply'}>
                      {companies.map(item => (
                        <MenuItem key={item.name} value={item._id}>{item.name}</MenuItem>
                      ))}
                  </TextField>
                  : <TextField
                      key={input}
                      id={input}
                      name={input}
                      label={input}
                      type='text'
                      value={formik.values[input]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[input] && Boolean(formik.errors[input])}
                      helperText={formik.touched[input] && formik.errors[input]}/>
                );
              }
                
              return inputArray;
            })()}
          <Button disabled={!formik.dirty || !formik.isValid || formik.isSubmitting} onClick={formik.handleSubmit} sx={{ alignSelf: 'end' }}>Update</Button>
        </Stack>
      </form>
      <Button onClick={() => navigate(`/${selected?.app_metadata?.admin ? 'admins': 'users'}/delete/${selected?.user_id}`)}>Delete</Button>
      <Button variant='text' onClick={() => navigate('/users')} sx={{ mt: 4 }}>Back to Users</Button>
    </Stack>
  );
}

export default EditUser;