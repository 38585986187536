import { combineReducers } from 'redux';

import solutions from './solutions';
import user from './user';

const rootReducer = combineReducers({
  solutions,
  user
});

export default rootReducer;