import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TransitionGroup, SwitchTransition} from 'react-transition-group';
import { useMediaQuery } from '@mui/material';
import { userSearchDb, userClearSolutions } from '../../store/actions';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import User from '../../components/User';

const UserList = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { loading, users, admins, companies, more, total } = useSelector(state => state.user);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companyFilter, setCompanyFilter] = useState('All');
  const [adminFilter, setAdminFilter] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(userClearSolutions());
  }, [dispatch]);

  const changeCompany = async e => {
    setCompanyFilter(e.target.value);
    setPage(1);

    const token = await getAccessTokenSilently();
    dispatch(userSearchDb(token, { page: 1, company: e.target.value === 'All' ? '' : e.target.value }));
  }

  const changePage = async (_, page) => {
    const token = await getAccessTokenSilently();

    setPage(page);

    dispatch(userSearchDb(token, {page: page, company: companyFilter === 'All' ? '' : companyFilter}));
  }

  return (
    <Stack direction='column' spacing={3}>
      <SwitchTransition>
        <Fade key={adminFilter}>
          {adminFilter
            ? <Typography variant='h3' component='h2' color='primary'>Aministrators</Typography>
            : <Typography variant='h3' component='h2' color='primary'>Users</Typography>}
        </Fade>
      </SwitchTransition>
      <Button size='large' onClick={() => navigate('/users/create')}>New User</Button>
      <Stack direction={mobile ? 'column' : 'row'} spacing={4}>
        <Stack>
          <Typography onClick={() => setAdminFilter(false)}>Users</Typography>
          <Switch checked={adminFilter} value={adminFilter} onChange={() => setAdminFilter(!adminFilter)}/>
          <Typography onClick={() => setAdminFilter(true)}>Administrators</Typography>
        </Stack>
        <Zoom in={!adminFilter} unmountOnExit>
          <TextField 
            id='comapny-filter'
            name='comapny-filter'
            label='Company Filter'
            select
            SelectProps={{
              align: 'left'
            }}
            sx={{ minWidth: '200px' }}
            value={companyFilter}
            onChange={changeCompany}>
            {companies.map(item => (
              <MenuItem key={item.name} value={item._id}>{item.name}</MenuItem>
              ))}
            <MenuItem key='all' value='All'>All Companies</MenuItem>
          </TextField>
        </Zoom>
      </Stack>
      <SwitchTransition>
        <Fade key={adminFilter}>
          <Grid container component='ul' justifyContent='center'>
            {loading
              ? <CircularProgress/>
              : adminFilter
                ? <TransitionGroup component={null}>
                    {admins.length > 0
                      ? admins.map(item => (
                          <Grow key={item.user_id}>
                            <User {...item}/>
                          </Grow>
                        ))
                      : <Grow key='placeholder'>
                          <Grid item component='li'>
                            <Typography>No Admins to Show</Typography>
                          </Grid>
                        </Grow>}
                  </TransitionGroup>
                : <TransitionGroup component={null}>
                    {users.length > 0
                      ? users.map(item => (
                          <Grow key={item.user_id}>
                            <User {...item}/>
                          </Grow>
                        ))
                      : <Grow key='placeholder'>
                          <Grid item component='li'>
                            <Typography>No Users to Show</Typography>
                          </Grid>
                        </Grow>}
                  </TransitionGroup>}
          </Grid>
        </Fade>
      </SwitchTransition>
      <Collapse in={!adminFilter}>
        <Pagination
          page={page}
          count={parseInt(Math.ceil(total/50))} 
          disabled={!more}
          onChange={changePage}/>
      </Collapse>
    </Stack>
  );
}

export default UserList;