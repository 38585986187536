import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  message: null,
  users: [],
  admins: [],
  companies: [],
  solutions: [],
  more: false,
  total: 0
};

const sort = (a, b) => ( a.name >= b.name ? 1 : -1);

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case actionTypes.USER_INIT: return { ...state, loading: true, error: null, message: null };
    case actionTypes.USER_FETCH_SUCCESS: return { 
      ...state, 
      loading: false, 
      users: action.data.users, 
      admins: action.data.admins ?? state.admins,
      companies: action.data.companies ?? state.companies,
      more: action.data.total > action.data.length,
      total: action.data.total };
    case actionTypes.USER_CREATE_SUCCESS: return { ...state, loading: false, message: 'User Created', users: [...state.users, action.data.user].sort(sort), companies: action.data.companies };
    case actionTypes.USER_EDIT_SUCCESS: return  { 
      ...state, 
      loading: false, 
      message: 'User Updated', 
      users: state.users.map(item => item.user_id === action.data.user_id ? action.data : item),
      companies: action.data.companies
    };
    case actionTypes.USER_DELETE_SUCCESS: return { ...state, loading: false, message: 'User Removed', users: state.users.filter(item => item.user_id !== action.data) };
    case actionTypes.USER_SEARCH_DB_SUCCESS: return { ...state, loading: false, users: action.data.users, admins: action.data.admins, more: action.data.total > action.data.length};
    case actionTypes.USER_FETCH_SOLUTIONS_SUCCESS: return { ...state, loading: false, solutions: action.data};
    case actionTypes.USER_ADD_SOLUTIONS_SUCCESS: return { 
      ...state, 
      loading: false, 
      solutions: state.solutions.map(item => action.data.solutions.includes(item._id) ? {...item, users: [...item.users, action.data.id]} : item) };
      case actionTypes.USER_REMOVE_SOLUTIONS_SUCCESS: return { 
        ...state, 
        loading: false, 
        solutions: state.solutions.map(item => action.data.solutions.includes(item._id) ? {...item, users: item.users.filter(user => user !== action.data.id)} : item) };
    case actionTypes.USER_COMPANY_CREATE_SUCCESS: return { ...state, loading: false, message: 'Company Created', companies: [...state.companies, action.data].sort(sort) };
    case actionTypes.USER_COMPANY_EDIT_SUCCESS:  return { 
      ...state, 
      loading: false, 
      message: 'Company Updated', 
      companies: state.companies.map(item => item._id === action.data.company ? { ...item, name: action.data.name } : item) };
    case actionTypes.USER_COMPANY_DELETE_SUCCESS: return { ...state, loading: false, message: 'Company Removed', companies: state.companies.filter(item => item._id !== action.data) };
    case actionTypes.USER_ERROR: return { ...state, loading: false, error: action.error };
    case actionTypes.USER_CLEAR_MESSAGE: return { ...state, message: null };
    case actionTypes.USER_CLEAR_ERROR: return { ...state, error: null };
    case actionTypes.USER_CLEAR_SOLUTIONS: return { ...state, solutions: [] };
    default: return state;
  }
};