import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { SwitchTransition } from 'react-transition-group';
import { useMediaQuery } from '@mui/material';
import { editCompany, deleteCompany } from '../../store/actions';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const companyValidation = object({
  name: string().required()
});

const Company = props => {

  const { _id, name } = props

  const { getAccessTokenSilently } = useAuth0();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);

  const companyForm = useFormik({
    initialValues: {
      name: name ?? ''
    },
    enableReinitialize: true,
    validationSchema: companyValidation,
    onSubmit: async values => {
      const token = await getAccessTokenSilently();

      dispatch(editCompany(token, { ...values, company: _id }));

      return;
    }
  });

  const askToConfirm = () => {
    setConfirm(true);

    setTimeout(() => setConfirm(false), 5000);
  }

  const remove = async () => {
    const token = await getAccessTokenSilently();

    dispatch(deleteCompany(token, _id ));

    setConfirm(false);
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={companyForm.handleSubmit}>
          <Stack direction={mobile ? 'column' : 'row'} justifyContent='space-between' >
            <TextField
              id='name' 
              name='name'
              label='Company Name'
              type='text'
              value={companyForm.values['name']}
              onChange={companyForm.handleChange}
              onBlur={companyForm.handleBlur}
              error={companyForm.touched['name'] && Boolean(companyForm.errors['name'])}
              helperText={companyForm.touched['name'] && companyForm.errors['name']}
              sx={{ width: '100%' }}/>
            <Stack>
              <Button disabled={!companyForm.dirty || !companyForm.isValid || companyForm.isSubmitting} onClick={companyForm.handleSubmit} sx={{ alignSelf: 'end' }} color='success'>
                Update
              </Button>
              <SwitchTransition>
                <Fade key={confirm}>
                  {
                    confirm 
                      ? <Button onClick={remove} color='warning'>Confirm</Button>
                      : <Button onClick={askToConfirm}>Delete</Button>
                  }
                </Fade>
              </SwitchTransition>
            </Stack>  
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
}

export default Company;