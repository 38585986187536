import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { solutionsFetch, userFetch } from './store/actions';
import decode from 'jwt-decode';

import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Loader from '@mui/material/CircularProgress';

import Login from './containers/Login';
import AdminHome from './containers/AdminHome';
import Auth from './containers/Auth';

import Companies from './containers/Companies/List';

import Solutions from './containers/Solutions/List';
import CreateSolution from './containers/Solutions/Create';
import EditSolution from './containers/Solutions/Edit';
import DeleteSolution from './containers/Solutions/Delete';
import ManageUsers from './containers/Solutions/ManageUsers';

import Users from './containers/Users/List';
import CreateUser from './containers/Users/Create';
import EditUser from './containers/Users/Edit';
import DeleteUser from './containers/Users/Delete';
import ManageSolutions from './containers/Users/ManageSolutions';

import Notifications from './containers/Notifications';

import Header from './components/Header';
import Footer from './components/Footer';
import Support from './components/Support';
import LostPage from './components/LostPage';

import './App.css';

function App() {

  const [admin, setAdmin] = useState(false);
  const { message: solutionMessage, error: solutionError } = useSelector(state => state.solutions);
  const { message: userMessage, error: userError } = useSelector(state => state.user);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
   (async () => {
      if (isAuthenticated) {

        const token = await getAccessTokenSilently();

        setAdmin(decode(token).permissions.includes('admin'));

        dispatch(solutionsFetch(token));
      }
   })();
  }, [isAuthenticated, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    (async () => {
      if (admin) {
        const token = await getAccessTokenSilently();

        dispatch(userFetch(token));
      }
    })();
  }, [admin, getAccessTokenSilently, dispatch, user]);

  return (
    <div className="App" style={{ 
      minHeight: window.innerHeight, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      overflow: 'hidden' }}>
      <CssBaseline/>
      <Header auth={isAuthenticated} admin={admin}/>
      <Notifications message={solutionMessage} error={solutionError}/>
      <Notifications message={userMessage} error={userError}/>
        <Stack component='main' sx={{ position: 'relative' }}>
          {isLoading
            ? <Loader/>
            : <Routes>
                {
                  isAuthenticated && admin
                    ? <Fragment>
                        <Route path='/' element={<AdminHome/>}/>
                        <Route path='/companies' element={<Companies/>}/>
                        <Route path='/solutions' element={<Solutions admin/>}/>
                        <Route path='/solutions/create' element={<CreateSolution/>}/>
                        <Route path='/solutions/edit/:id' element={<EditSolution/>}/>
                        <Route path='/solutions/delete/:id' element={<DeleteSolution/>}/>
                        <Route path='/solutions/users/:id' element={<ManageUsers/>}/>
                        <Route path='/users' element={<Users/>}/>
                        <Route path='/users/create' element={<CreateUser/>}/>
                        <Route path='/users/edit/:id' element={<EditUser/>}/>
                        <Route path='/users/delete/:id' element={<DeleteUser/>}/>
                        <Route path='/users/solutions/:id' element={<ManageSolutions/>}/>
                        <Route path='/admins/edit/:id' element={<EditUser/>}/>
                        <Route path='/admins/delete/:id' element={<DeleteUser/>}/>
                        <Route path='/login' element={<Auth/>}/>
                        <Route path='/logout' element={<Auth/>}/>
                      </Fragment>
                    : isAuthenticated && !admin
                      ? <Fragment>
                          <Route path='/' element={<Solutions/>}/>
                          <Route path='/login' element={<Auth/>}/>
                          <Route path='/logout' element={<Auth/>}/>
                        </Fragment>
                      : <Fragment>
                          <Route path='/' element={<Login/>}/>
                          <Route path='/login' element={<Auth/>}/>
                          <Route path='/authorize' element={<Auth/>}/>
                          <Route path='/logout' element={<Auth/>}/>
                        </Fragment>
                }
                <Route path='/support' element={<Support/>}/>
                <Route path='/*' element={<LostPage/>}/>
              </Routes>}
        </Stack>
      <Footer/>
    </div>
  );
}

export default App;
