import * as actionTypes from './actionTypes';

export const solutionsInit = () => ({ type: actionTypes.SOLUTIONS_INIT });

export const solutionsFetch = (token, data) => ({ type: actionTypes.SOLUTIONS_FETCH, token, data });
export const solutionsFetchSuccess = data => ({ type: actionTypes.SOLUTIONS_FETCH_SUCCESS, data });

export const solutionsCreate = (token, data) => ({ type: actionTypes.SOLUTIONS_CREATE, token, data });
export const solutionsCreateSuccess = data => ({ type: actionTypes.SOLUTIONS_CREATE_SUCCESS, data });

export const solutionsEdit = (token, data) => ({ type: actionTypes.SOLUTIONS_EDIT, token, data });
export const solutionsEditSuccess = data => ({ type: actionTypes.SOLUTIONS_EDIT_SUCCESS, data });

export const solutionsDelete = (token, data) => ({ type: actionTypes.SOLUTIONS_DELETE, token, data });
export const solutionsDeleteSuccess = data => ({ type: actionTypes.SOLUTIONS_DELETE_SUCCESS, data });

export const solutionsFetchUsers = (token, data) => ({ type: actionTypes.SOLUTIONS_FETCH_USERS, token, data });
export const solutionsFetchUsersSuccess = data => ({ type: actionTypes.SOLUTIONS_FETCH_USERS_SUCCESS, data });

export const addUsers = (token, data) => ({ type: actionTypes.SOLUTIONS_ADD_USERS, token, data });
export const addUsersSuccess = data => ({ type: actionTypes.SOLUTIONS_ADD_USERS_SUCCESS, data });

export const removeUsers = (token, data) => ({ type: actionTypes.SOLUTIONS_REMOVE_USERS, token, data });
export const removeUsersSuccess = data => ({ type: actionTypes.SOLUTIONS_REMOVE_USERS_SUCCESS, data });

export const solutionsError = error => ({ type: actionTypes.SOLUTIONS_ERROR, error });

export const solutionsClearMessage = () => ({ type: actionTypes.SOLUTIONS_CLEAR_MESSAGE });
export const solutionsClearError = () => ({ type: actionTypes.SOLUTIONS_CLEAR_ERROR });
export const solutionsClearUsers = () => ({ type: actionTypes.SOLUTIONS_CLEAR_USERS })