import React, { useState, useEffect, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react'; 
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { userDelete } from '../../store/actions';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const DeleteSolution = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { users, admins } = useSelector(state => state.user);
  const { id } = useParams();
  const { pathname } = useLocation();

  const [selected, setSelected] = useState(null);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pathname.includes('admins')) {
      setSelected(admins.find(item => item.user_id === id));
    } else {
      setSelected(users.find(item => item.user_id === id));
    }
  }, [id, pathname, admins, users]);

  const deleteSolution = async () => {
    const token = await getAccessTokenSilently();

    dispatch(userDelete(token, id));
    navigate('/users');
  }

  return (
    <Stack direction='column' spacing={3}>
      {selected === null
        ? <CircularProgress/>
        : <Fragment>
            <Typography variant='h4' component='h2'>Delete {selected?.name}?</Typography>
            <Typography variant='h6' color='primary' sx={{ m: 0 }}>This action cannot be undone</Typography>
            <Stack>
              <Button onClick={deleteSolution}>Yes, Delete</Button>
              <Button onClick={() => navigate(pathname.replace('delete', 'edit'))}>No, Cancel</Button>
            </Stack>
          </Fragment>}
    </Stack>
  );
}

export default DeleteSolution;