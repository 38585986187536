import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: { main: '#D40000' },
    secondary: { main: '#949699' }
  },
  typography: {
    fontFamily: 'roboto, sans-serif'
  },
  components: {
    MuiAlert: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      }
    },
    MuiCard: {
      defaultProps: {
        raised: true
      },
      styleOverrides: {
        root: {
          margin: '1rem',
          maxWidth: '90vw'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          align: 'left'
        },
        subheaderTypographyProps: {
          align: 'left'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          overflowY: 'auto'
        }
      }
    },
    MuiCircularProgress: {
      defaultProps:{
        thickness: 2,
        size: 200
      }
    },
    MuiDivider: {
      defaultProps: {
        variant: 'middle'
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          listStyleType: 'none',
          margin: 0,
          padding: 0
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '85vh'
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          '& > *': { width: 150 },
          marginRight: 6
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        disableGutters: true
      }
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          whiteSpace: 'normal'
        }
      }
    },
    MuiPagination: {
      defaultProps: {
        color: 'primary',
        variant: 'text',
        shape: 'rounded'
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      }
    },
    MuiStack: {
      defaultProps: {
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        spacing: 1
      }
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      variant: 'standard'
    }
  },
});

export default Theme;