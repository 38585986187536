import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string, boolean } from 'yup';
import { userCreate } from '../../store/actions';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CreateCompany from '../Companies/Create';
import { array } from 'yup';

const userValidation = object({
  email: string().email().required(),
  name: string().required(),
  companies: array().min(1).required(),
  admin: boolean()
});

const CreateUser = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { companies } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userForm = useFormik({
    initialValues: {
      email: '',
      name: '',
      companies: [],
      admin: false
    },
    validationSchema: userValidation,
    onSubmit: async (values, userForm) => {
      const token = await getAccessTokenSilently();

      dispatch(userCreate(token, values));
    
      userForm.resetForm();

      return;
    }
  });

  return (
    <Stack direction='column' spacing={4}>
      <Typography variant='h4' component='h2' color='primary'>Create User</Typography>
      <form onSubmit={userForm.handleSubmit}>
        <Stack direction='column' alignItems='stretch' width='90vw' maxWidth='800px'>
          {(() => {
            const inputArray = [];
            
            for (let input in userForm.values) {
              inputArray.push(
                input === 'admin'
                  ? <Stack key='checkbox'>
                      <Typography>Check for Admin Privilege</Typography>
                      <Checkbox 
                        key={input}
                        id={input}
                        name={input}
                        label={input}
                        checked={userForm.values[input]}
                        value={userForm.values[input]}
                        onChange={userForm.handleChange}/>
                    </Stack>
                  : input === 'companies'
                      ? <TextField
                          select
                          SelectProps={{
                            align: 'left',
                            multiple: true
                          }}
                          key={input}
                          id={input}
                          name={input}
                          label={input}
                          value={userForm.values[input]}
                          onChange={userForm.handleChange}
                          error={userForm.touched[input] &&Boolean(userForm.errors[input])}
                          helperText={Boolean(userForm.errors[input]) ? userForm.errors[input] : 'Select All Comapnies that Apply'}>
                          {companies.map(item => (
                            <MenuItem key={item.name} value={item._id}>{item.name}</MenuItem>
                          ))}
                        </TextField>
                      : <TextField
                          key={input}
                          id={input}
                          name={input}
                          label={input}
                          type='text'
                          value={userForm.values[input]}
                          onChange={userForm.handleChange}
                          onBlur={userForm.handleBlur}
                          error={userForm.touched[input] && Boolean(userForm.errors[input])}
                          helperText={userForm.touched[input] && userForm.errors[input]}/>
                  );
                }
                
                return inputArray;
              })()}
          <Button disabled={!userForm.dirty || !userForm.isValid || userForm.isSubmitting} onClick={userForm.handleSubmit} sx={{ alignSelf: 'end' }}>Create</Button>
        </Stack>
      </form>
      <CreateCompany/>
      <Button variant='text' onClick={() => navigate('/users')} sx={{ mt: 4 }}>Back to Users</Button>
    </Stack>
  );
}

export default CreateUser;