import { put, delay, spawn } from 'redux-saga/effects';
import { server } from '../../assets/axios/index';
import * as actions from '../actions';

function* clear(type) {
  yield delay(10_000);
  if (type === 'message') yield put(actions.solutionsClearMessage());
  if (type === 'error') yield put(actions.solutionsClearError());
}

export function* fetchSolutionsSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { data, error } } = yield server.get('/solutions', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        name: action.data?.name,
        page: action.data?.page
      }
    });

    if (status === 200) {
      yield put(actions.solutionsFetchSuccess(data));
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
};

export function* createSolutionSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { data, error } } = yield server.post('/solutions', action.data, {
        headers: {
          'authorization': `Bearer ${action.token}`
        }
    });

    if (status === 200) {
      yield put(actions.solutionsCreateSuccess(data));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* editSolutionSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { data, error } } = yield server.put('/solutions', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.solutionsEditSuccess({...action.data, company: data}));
      yield spawn(clear, 'message');
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* deleteSolutionSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { error } } = yield server.delete('/solutions', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        solution: action.data
      }
    });

    if (status === 200) {
      yield put(actions.solutionsDeleteSuccess(action.data));
     yield spawn(clear, 'message');

    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* solutionFetchUsersSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { data , error } } = yield server.get('/solutions/users', {
      headers: {
        'authorization': `Bearer ${action.token}`
      },
      params: {
        company: action.data
      }
    });

    if (status === 200) {
      yield put(actions.solutionsFetchUsersSuccess(data));
    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* addUsersSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { error } } = yield server.post('/solutions/users', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.addUsersSuccess(action.data));
     yield spawn(clear, 'message');

    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
}

export function* removeUsersSaga(action) {
  yield put(actions.solutionsInit());
  try {
    const { status, data: { error } } = yield server.patch('/solutions/users', action.data, {
      headers: {
        'authorization': `Bearer ${action.token}`
      }
    });

    if (status === 200) {
      yield put(actions.removeUsersSuccess(action.data));
     yield spawn(clear, 'message');

    } else {
      throw Error(error);
    }
  } catch (err) {
    yield put(actions.solutionsError(err.message));
    yield spawn(clear, 'error');
  }
}